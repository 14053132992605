import React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import "../../assets/main.css";

class Thread extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: false,
      data: [],
      comments: [],
      page: this.props.page,
    };
  }
  async componentDidMount() {
    this.getData();
  }

  async getData() {
    let res = await fetch(
      "https://api.working-capital.com/comments/" + this.props.slug,
      {
        headers: {
          Accept: "application/json",
        },
        method: "GET",
      }
    );
    let json = await res.json();
    let c = json["arr"];
    console.log(json);
    this.setState({ data: json });
    this.setState({ comments: c });
  }

  componentDidUpdate(prevProps, prevState) {
    // update state
    console.log("CDU", prevProps, prevState);

    if (prevState.page !== this.state.page) {
      //api call
      this.getData();
    }
  }

  close = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  render() {
    const mainContent = (
      <div className="lg:mr-12 mr-1 ml-1 items-center">
        <p className="m-1 p-1 text-xl text">
          Comments
        </p>
        {}

        {this.state.data.map((comment) => (
          <div>
            <Comment
              key={comment.name}
              comment={comment}
              slug={this.props.slug}
            />
          </div>
        ))}
        <div className="text-center">
          <button
            onClick={this.close}
            className="bg-blue-600 border-none cursor-pointer hover:bg-blue-800 text-lg w-full text-white font-bold py-4 px-6"
            type="button"
          >
            Submit a Comment
          </button>
        </div>
        <Form
          close={this.close}
          toggled={this.state.toggle}
          thread={this.props.title}
          id={null}
          slug={this.props.slug}
        />
      </div>
    );

    return <div>{mainContent}</div>;
  }
}

const Form = ({ close, toggled, thread, id, slug }) => {
  const [email, setEmail] = useState("");

  const [name, setName] = useState("");

  const [content, setContent] = useState("");

  const post = async () => {
    if (content === "" || name === "" || email === "") {
      console.log("Dont post");
    } else {
      console.log(name + "  " + email + "  " + "  " + content);
      setName("");
      setEmail("");
      setContent("");
      close();
      let res = await fetch(
        "https://api.working-capital.com/comments/" + slug,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("token"),
          },
          body: JSON.stringify({
            name: name,
            email: email,
            content: content,
            reply_to: id,
          }),
        }
      );
      let json = await res.json();
      console.log(json);
    }
  };

  return (
    <div className="relative w-full">
      {toggled ? (
        <div className="flex mb-auto text-left bg-opacity-75 content-center fixed top-0 left-0 z-10 h-screen min-w-full max-w-full">
          <div className="w-full bg-gray-700 bg-opacity-50 ">
            <div className="bg-white m-auto shadow-md mt-24 p-6 m-3 lg:w-1/3 w-3/4">
              <div className="flex">
                <div className="flex-grow">
                  <span className="text-xl pt-0 m-0 mb-10">
                    Reply to {thread}
                  </span>
                </div>
                <div className="flex-shrink">
                  <button
                    className="ml-3 text-right bg-white cursor-pointer border-none text-2xl"
                    onClick={close}
                  >
                    x
                  </button>
                </div>
              </div>

              <br></br>
              <div className="mb-4 pt-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  for="username"
                >
                  Your Name
                </label>
                <input
                  className="shadow-lg rounded-md appearance-none border w-full rounded-sm p-0 h-8 text-gray-700"
                  id="Name"
                  type="text"
                  onChange={(event) => setName(event.target.value)}
                  placeholder=""
                ></input>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  for="username"
                >
                  Your Email
                </label>
                <input
                  className="shadow-gl rounded-md appearance-none border w-full p-0 rounded-sm h-8 text-gray-700"
                  id="Email"
                  type="text"
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder=""
                ></input>
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  for="comment"
                >
                  Comment
                </label>
                <input
                  className="h-32 shadow-lg rounded-md appearance-none border rounded-sm  w-full p-0 text-gray-700"
                  id="comment"
                  type="text"
                  onChange={(event) => setContent(event.target.value)}
                  placeholder=""
                ></input>
              </div>
              <div className="text-right">
                <button
                  className="w-1/4 text-right bg-blue-600 border-none cursor-pointer hover:bg-blue-800 text-white font-bold rounded-md"
                  type="button"
                  onClick={post}
                >
                  <p className="text-center">Submit</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const Comment = ({ comment, slug }) => {
  const [toggled, setToggle] = useState(false);

  const [thread, setThread] = useState("");

  const [id, setid] = useState(null);

  const nestedComments = (comment.replies || []).map((comment) => {
    return <Comment key={comment.name} comment={comment} type="child" />;
  });

  const createMarkup = (cont) => {
    return { __html: cont };
  };

  const click = (e) => {
    setToggle(!toggled);
    setThread(e.target.name);
    setid(e.target.id);
  };

  const close = () => {
    setToggle(!toggled);
  };

  const getDate = (d) => {
    var d = new Date(d);
    var str = d.toDateString();
    var day = str.substring(4, str.length);
    return day;
  };
  return (
    <div className="relative pb-2">
      <div
        className="bg-white  text-left ml-2 rounded-md mt-4 border-2 border-solid border-gray-200"
        style={{
          transition: ".6s all",
        }}
      >
        <div className="p-4">
          <p className="text-lg p-0 font-bold m-1">{comment.name}</p>
          <p className="text-sm p-0 m-1">{getDate(comment.approved)}</p>
          <p
            className="text-sm"
            dangerouslySetInnerHTML={createMarkup(comment.content)}
            className="text-sm text-black m-1"
          ></p>
          <p>
            <button
              onClick={(event) => click(event)}
              className="text-green-600 pt-0 m-1 cursor-pointer border-none text-md hover:text-green-800 text-left bg-white"
              id={comment.id}
              name={comment.name}
            >
              Reply
            </button>
          </p>
        </div>
        {nestedComments}
      </div>
      <Form
        close={close}
        toggled={toggled}
        thread={thread}
        id={id}
        slug={slug}
      />
    </div>
  );
};

export default Thread;
