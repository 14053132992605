import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import Img from "gatsby-image";
import Layout from "../components/post-layout";
import Tags from "../components/body-tags";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import '../assets/main.css'
import heroStyles from "../components/hero.module.css";
import SideContent from '../components/side-components/lender-side.js'
import Thread from '../components/comment-components/comment-thread.js'
import Compare from '../components/compare-components/dropdown-compare.js'

class ArticlePost extends React.Component {
    
  render() {
    const post = get(this.props, "data.contentfulArticle");
    const siteTitle = get(this.props, "data.site.siteMetadata.title");
    const articles = get(this, 'props.data.allContentfulLender.edges')
    
   

    const mainContent = (
        <div>
        <div className="flex" style={{flexWrap: 'wrap',}}>
                <div class="lg:w-1/4 w-full ">
                        <div className="js-toc sticky top-0 pt-12" style={{background: "none"}}></div>
                   
                </div>
                    <div class="lg:w-3/4 w-full">
                    <img class="w-auto max-w-full" src={post.image.file.url} alt="Room Image" />
                    <h1 className="pt-4">{post.title}</h1>
                        <div className="js-toc-content px-2">
                            <Tags body={post.body && post.body.json ? post.body.json : null}/>
                        </div>
                         
                         
                        <Thread slug={post.slug} title={post.title} className="mt-3 p-2 mx-auto"/>
                    </div>
                    
                    <Compare className="m-10 p-2"/>
                </div>
                
                
            </div>
       
    );

  

    return (
    <div>
      <Layout location={this.props.location} title = {post.title} image = {post.image.file.url} mainContent={mainContent} sideContent={<SideContent articles={articles} />}>
          <Helmet title={`${post.title} | ${siteTitle}`} />
          
      </Layout>
        
      </div>
    );
  }
}

export default ArticlePost;

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {

  allContentfulLender(limit: 8,sort: { fields: [title], order: DESC }) {
      edges {
        node {
        tagList {
            title
          }
          title
          slug
          description {
            description
          }
          image {
            file {
                url
            }
         }
        }
      }
    }

    contentfulArticle(slug: { eq: $slug }) {
      title
      contentful_id
      
      slug
      body {
        json
      } 
      image {
            file {
                url
            }
         }
    }
  }
`;
